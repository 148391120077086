.logo {
    align-self: center;
    max-width: 6rem;
    max-height: 6rem;
}

.rigthTabs {
    display: flex;
    justify-content: flex-end;
    margin-left: auto !important;
    margin-right: 1rem !important;
    min-width: 22rem;
}

.leftTabs {
    margin-right: auto !important;
    margin-left: 1rem !important;
    min-width: 25rem;
}

.transparent {
    display: flex;
    justify-content: center;
    align-items: center;
}

.transparent:hover {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: white;
        transition-duration: 1s;
}

.transparent:hover .tabtext {
    filter: invert(100%);
    transition-duration: 0.5s;
}

.transparent:hover .logo {
    /* filter: invert(100%); */
    transition-duration: 0.5s;
}


.tab {
    color: white !important;
    font-size: large !important;
}

.tab::before{
    content: "";
    position: absolute;
    width: 0;
    height: 1px;
    bottom: 10px;
    left: 15px;
    background-color: rgb(99, 100, 99);
    visibility: "hidden";
    transition: all 0.5s ease-in-out;
  
}
.tab:hover::before{
visibility: visible;
    width: 30%;
}


.white {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    box-shadow: 0 3px 1rem rgb(0, 0, 0, .3);
    transition: all 0.5s ease-in-out;
}


.white .tabtext {
    filter: invert(100%)
}

.white .logo {
    /* filter: invert(100%); */
}





.wrapper{
  width: 100%;
  max-width: 1440px;
  overflow-x: hidden;
  overflow-y: auto;
  margin: 0 auto;
}

.navbar{
  width: 100%;
  height: 80px;
  padding: 30px;
  background-color: #01061D;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbar1{
  width: 100%;
  height: 90px;
  background-color: #e9eaed;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.toggleicon {
  display: block;
  color: #0f0e0e;
  position: absolute;
  top: 28px;
  right: 20px;
  z-index: 1001;
}

.navbar .logo1{
  color: #D9D2C3;
  font-size: 28px;
  font-weight: 600;
  max-width: 6rem;
  max-height: 6rem;
  
}

.navbar1 .logo1{
  color: #D9D2C3;
  font-size: 28px;
  font-weight: 600;
  max-width: 6rem;
  max-height: 6rem;
  
}

.navbar .links{
  list-style: none;
  display: flex;
  color: #D9D2C3;
  cursor: pointer;
  text-decoration: none;
}

.navbar1 .links li{
  display: none;
  margin: 0 15px;
  font-size: 14px;
  font-weight: 600;
}

.navbar.expanded .toggleicon{
  display: flex;
}


.navbar.expanded{
  height: 100vh;
  transition: 0.5s all ease-in-out;
  
}

.navbar.expanded .links{
  position: absolute;
  top: 120px;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 1;
}

.navbar.expanded .links li{
  margin: 15px 0;
  font-size: 24px;
}

.navbar1{
  position: relative;
  transition: 0.5s all ease-in-out;
}



.navbar.expanded .toggleicon{
  display: block;
  color: #D9D2C3;
  position: absolute;
  top: 28px;
  right: 20px;
  z-index: 1001;
}

a {
  text-decoration: none;
  color: #D9D2C3;
}

.WhatsAppIcon {
  display: block;
  position: fixed;
  border-radius: 30% !important;
  height: 3rem;
  bottom: 20px;
  right: 20px; 
  z-index: 999;
}

.box {
  display: block;
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 999;
}

.box2 {
  display: block;
  position: fixed;
  bottom: 1px;
  right: 1px;
  z-index: 999;
}

