.ramasimage {
    position: absolute;
    top: 0;
    left: 0;
    min-height: 25rem;
    max-height: 25rem;
    width: 100%;
    object-fit: cover;
    filter: blur(0.5px);
    -webkit-filter: blur(0.5px);
}

.text {
    z-index: 100;
    margin-top: 10rem !important;
    margin-left: auto !important;
    margin-right: auto !important;
    text-shadow: 0.5px 0.5px 0.5px black;
    
}
.text2 {
    z-index: 100;
    margin-top: 1rem !important;
    margin-left: auto !important;
    margin-right: auto !important;
    text-shadow: 0.5px 0.5px 0.5px black;
}

.textstyle {
    color: rgb(70, 70, 77);
}

.card {
    text-decoration: none;
}